import React, { memo, useRef, useLayoutEffect } from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import { gsap } from "gsap";
import PageLayout from "../layouts/PageLayout";

const exitPage = (element) => gsap.to(element, { x: -20, opacity: 0, duration: 0.5 });

const seoParams = {
	title: "404: Not found",
	description: "Page not found",
};

const NotFoundPage = memo(() => {
	const animationParent = useRef();

	useLayoutEffect(() => {
		const selector = gsap.utils.selector(animationParent);
		const animation = gsap.from(selector("h1, h2, p, a"), {
			duration: .8,
			ease: "power3.inOut",
			y: 20,
			opacity: 0,
			stagger: .1
		});

		return () => animation.kill();
	}, []);

	return (
		<PageLayout seo={seoParams} >
			<h1>404: Not Found</h1>
			<p>Je pense que vous vous êtes égaré !</p>
			<TransitionLink
				style={{ padding: "14px 18px" }}
				exit={{
					trigger: ({ node }) => exitPage(node),
					length: 1
				}}
				className="button"
				to="/">
				Repartir sur le bon chemin !
			</TransitionLink>
		</PageLayout>
	);
});

export default NotFoundPage;